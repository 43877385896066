$cardBackground: #2c2c2c; // A slightly lighter shade for better contrast

/* Burger menu icon */
.burger-menu {
  display: none;
  font-size: 30px;
  top: 15px;
  left: 15px;
  background: none;
  border: none;
  color: rgb(218, 6, 6);
  cursor: pointer;
  z-index: 999;
  width: fit-content;
  font-size: medium;
  min-width: unset;
}

/* Sidebar hidden by default on mobile */
.sidebar {
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  z-index: 9999;
}

/* Sidebar visible when open */
.sidebar.open {
  transform: translateX(0);
}

/* Show the burger menu and hide sidebar on smaller screens */
@media (max-width: 768px) {
  .mega-wrapper{
    display: flex;
    flex-direction: column;
  }
  .burger-menu {
    display: block;
    top: 15px;
    left: 15px;
    position: absolute;
  }

  .sidebar {
    transform: translateX(-100%);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
  }

  .sidebar.open {
    width: 100vw;
    background-color: #333;
    transform: translateX(0);
    .makeStyles-drawerPaper-2 {
      width: fit-content;
    }
  }
}

/* On larger screens, the sidebar is always visible and burger is hidden */
@media (min-width: 768px) {
  .burger-menu {
    display: none;
  }

  .sidebar {
    background-color: $cardBackground;
    transform: translateX(0);
    position: relative;
  }
}
