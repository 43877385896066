/* Global Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Landing Page Styles */
.landing-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  font-family: "Poppins", sans-serif;
  background-color: #000;
  color: #fff;
  text-align: center;
  overflow: hidden;
  animation: fadeIn 1.5s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Robot Eyes Animation */
.robot-eyes-animation {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  animation: eyesEnter 2s ease forwards;
  // position: absolute;
  top: 20%;
}

@keyframes eyesEnter {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.eye {
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 50%;
  margin: 0 15px;
  animation: blink 5s infinite;
}

@keyframes blink {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.1, 1);
  }
}

/* Welcome Message */
.welcome-message {
  opacity: 0;
  animation: textEnter 2s ease forwards 1s;
  background: transparent;
  text-align: center;
  padding: 20px;
}

.welcome-text {
  font-size: 2.8rem;
  letter-spacing: 0.15rem;
  background: linear-gradient(90deg, #0ff, #00f, #ff00ff, #0ff);
  background-size: 200% auto;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 10px rgba(0, 255, 255, 0.8), 0 0 20px rgba(0, 255, 255, 0.6),
    0 0 30px rgba(0, 255, 255, 0.4), 0 0 40px rgba(0, 255, 255, 0.2);
  animation: textGradient 3s infinite alternate;
}

.subtext {
  font-size: 1.3rem;
  margin-top: 10px;
  background: linear-gradient(90deg, #ff00ff, #f00, #ff0, #ff00ff);
  background-size: 200% auto;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 10px rgba(255, 0, 255, 0.8), 0 0 20px rgba(255, 0, 255, 0.6),
    0 0 30px rgba(255, 0, 255, 0.4), 0 0 40px rgba(255, 0, 255, 0.2);
  animation: textGradient 3s infinite alternate;
}

/* Gradient animation effect */
@keyframes textGradient {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

/* Fade-in animation */
@keyframes textEnter {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Action Buttons */
.action-buttons {
  margin-top: 40px;
  opacity: 0;
  animation: buttonsEnter 2.5s ease forwards 2s;
}

@keyframes buttonsEnter {
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.action-button {
  background: none;
  border: 2px solid #fff;
  color: #fff;
  padding: 10px 40px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 10px;
  letter-spacing: 0.05rem;
}

.action-button:hover {
  background: #fff;
  color: #000;
  transform: scale(1.1);
}

.login-btn {
  border-color: #fff;
}
.continue-btn {
  border-color: #888;
}

/* Responsive Design */
@media (max-width: 768px) {
  .welcome-text {
    font-size: 2rem;
  }
  .eye {
    width: 40px;
    height: 40px;
  }
  .action-button {
    font-size: 1rem;
    padding: 8px 30px;
  }
}
.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
