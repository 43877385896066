// Variables
$padding-container: 20px 40px;
$margin-large: 20px 0;
$label-color: #555;
$button-color: white;
$button-bg-color: rebeccapurple;
$input-padding: 10px;
$label-padding: 10px;
$label-margin: 3px;

// Mixin for reusable button styles
@mixin button-styles {
  color: $button-color;
  background-color: $button-bg-color;
  margin: $margin-large;
}

.users-manager-container {
  width: 100%;
  height: 100%;
  padding: $padding-container;

  .title {
    margin: $margin-large;
  }

  .submit-button,
  .create-user-button {
    @include button-styles;
  }

  .create-user-popup {
    label {
      cursor: pointer;
      color: $label-color;
      display: block;
      padding: $label-padding;
      margin: $label-margin;
    }

    input {
      padding: $input-padding;
    }
  }
}
