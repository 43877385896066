// Variables
$container-padding: 20px;
$container-width: 100%;
$border-radius: 5px;
$margin-auto: 0 auto;
$margin-small: 10px 0px;
$margin-medium: 15px 0px;
$button-bg-color: #4caf50;
$button-hover-bg-color: #45a049;
$slider-bg-color: #ccc;
$slider-checked-bg-color: #2196f3;
$label-font-weight: bold;
$text-input-border-color: #ccc;
$text-input-padding: 8px;
$switch-width: 60px;
$switch-height: 34px;
$switch-slider-size: 26px;
$switch-transition-time: 0.4s;

// Mixin for buttons
@mixin button-styles {
  background-color: $button-bg-color;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: $border-radius;
  cursor: pointer;
  margin-right: 10px;

  &:hover {
    background-color: $button-hover-bg-color;
  }
}

.settings-container {
  margin: $margin-auto;
  padding: $container-padding;
  width: $container-width;
  border-radius: $border-radius;

  .setting-item {
    margin-bottom: $margin-medium;
  }
  .main-title {
    padding: $container-padding 0;
    margin-bottom: $margin-small;
  }

  .sub-title {
    margin: $margin-small;
  }

  label {
    font-weight: $label-font-weight;
    margin-right: $margin-small;
  }

  input[type="text"] {
    width: calc(100% - 90px);
    padding: $text-input-padding;
    border-radius: $border-radius;
    border: 1px solid $text-input-border-color;
  }

  .Token {
    padding: 10px;
    margin: 10px 0px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    .setting-item-label {
      font-weight: bold;
      // margin-right: 20px;
      margin: 10px 0px;
      width: 100%;
    }
    input {
      background: transparent;
      font-size: 0.9rem;
      color: white;
      width: unset;
    }
  }
  .switch {
    position: relative;
    display: inline-block;
    width: $switch-width;
    height: $switch-height;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $slider-bg-color;
      border-radius: $switch-height;
      transition: $switch-transition-time;

      &:before {
        position: absolute;
        content: "";
        height: $switch-slider-size;
        width: $switch-slider-size;
        left: 4px;
        bottom: 4px;
        background-color: white;
        border-radius: 50%;
        transition: $switch-transition-time;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      }
    }

    input:checked + .slider {
      background-color: $slider-checked-bg-color;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px $slider-checked-bg-color;
    }

    input:checked + .slider:before {
      transform: translateX(26px);
    }
  }

  button {
    @include button-styles;
  }

  .text-switch {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 20px;
    cursor: pointer;
    border: 1px solid $text-input-border-color;
    position: relative;
    display: flex;
    width: fit-content;
    gap: 20px;

    .selected {
      background-color: $button-bg-color;
      color: white;
      border: none;
    }
  }

  .slider {
    display: inline-block;
    width: 30px;
    height: 20px;
    background-color: $slider-bg-color;
    border-radius: 10px;
    margin: 0 5px;
  }
}
