// // PasswordManagerPage.scss
// @import "./index.scss";
// .password-manager-container {
//   min-width: 800px;
//   margin: auto;
//   padding: 20px;
//   font-family: Arial, sans-serif;
//   button {
//     border: none;
//     background-color: transparent;
//     color: white;
//     cursor: pointer;
//     border-radius: 10px;
//     border: solid 1px gray;
//     padding: 10px;
//     transition: all ease 0.3s;
//   }
//   button:hover {
//     background-color: rebeccapurple;
//   }
//   .buttons {
//     display: flex;
//     gap: 10px;
//   }
// }

// h1 {
//   text-align: center;
//   margin-bottom: 20px;
// }

// .container {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   margin: auto 0px;
//   gap: 10px;
// }

// .container select {
//   padding: 10px;
//   background-color: transparent;
//   color: white;
//   font-size: 16px;
//   border: 1px solid #ccc;
//   cursor: pointer;
//   min-width: 200px;
//   border-radius: 5px;
//   outline: none;
//   option {
//     background-color: $mainColor;
//   }
// }

// .passwords-table {
//   width: 100%;
//   border-collapse: collapse;
//   margin: 20px 0;
// }

// .passwords-table th,
// .passwords-table td {
//   padding: 12px;
//   border: 1px solid #ddd;
//   text-align: left;
// }

// .passwords-table th {
//   background-color: transparent;
// }

// .actions {
//   display: flex;
//   align-items: center;
// }

// .actions button {
//   padding: 8px 16px;
//   margin-right: 10px;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
//   background-color: #007bff;
//   color: white;
//   font-size: 14px;
// }

// .actions button:hover {
//   background-color: #0056b3;
// }

// .new-service-modal {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.5);
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .modal-overlay {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .modal {
//   background-color: white;
//   padding: 20px;
//   border-radius: 8px;
//   color: black;
//   box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Shadow effect */
// }

// .close-btn {
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   background: none;
//   border: none;
//   font-size: 1.5rem;
//   cursor: pointer;
// }

// .modal-content {
//   .thing {
//     display: flex;
//     gap: 10px;
//     flex-direction: column;
//     input {
//       margin-top: 10px;
//       padding: 10px;
//       border-radius: 5px;
//     }
//     button {
//       background-color: rebeccapurple;
//     }
//   }
// }
@import "../index.scss";

.password-manager-container {
  min-width: 800px;
  margin: auto;
  padding: 20px;
  font-family: Arial, sans-serif;

  button {
    border: none;
    background-color: transparent;
    color: white;
    cursor: pointer;
    border-radius: 10px;
    border: solid 1px gray;
    padding: 10px;
    transition: all ease 0.3s;
  }

  button:hover {
    background-color: rebeccapurple;
  }

  .buttons {
    display: flex;
    gap: 10px;
  }
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto 0px;
  gap: 10px;

  select {
    padding: 10px;
    background-color: transparent;
    color: white;
    font-size: 16px;
    border: 1px solid #ccc;
    cursor: pointer;
    min-width: 200px;
    border-radius: 5px;
    outline: none;

    option {
      background-color: $mainColor;
    }
  }
}

.passwords-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;

  th,
  td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
  }

  th {
    background-color: transparent;
  }
}

.actions {
  display: flex;
  align-items: center;

  button {
    padding: 8px 16px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    font-size: 14px;
  }

  button:hover {
    background-color: #0056b3;
  }
}

/* Assuming your modal container has a class .modal */
.modal {
  background-color: rgba(0, 0, 0, 0.8); /* Dark transparent background */
  color: white; /* White text for contrast */
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  margin: 0 auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  position: relative;
}

.modal .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: white;
}
/* Responsive Styles */
@media (max-width: 768px) {
  .password-manager-container {
    min-width: 100%;
    padding: 10px;
  }

  .container {
    flex-direction: column;
    gap: 20px;
  }

  .passwords-table th,
  .passwords-table td {
    font-size: 14px;
    padding: 8px;
  }

  .actions button {
    padding: 6px 12px;
    font-size: 12px;
    margin-right: 5px;
  }
}

@media (max-width: 480px) {
  .password-manager-container {
    padding: 5px;
  }

  .container select {
    font-size: 14px;
    min-width: 100%;
  }

  .buttons {
    flex-direction: column;
    gap: 5px;
  }

  .passwords-table th,
  .passwords-table td {
    font-size: 12px;
  }

  .actions button {
    padding: 4px 8px;
    font-size: 10px;
  }

  .modal {
    padding: 15px;
    width: 90%;
  }
}
